.login_box {
	max-width: 350px;
	padding: 20px;
	color: white;
	border-radius: 0.25rem;
	/* background-image: linear-gradient(120deg, #0094fa 0%, #002579 100%); */
	background-color: #ff6a00;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.deep-blue-gradient {
	background-image: linear-gradient(120deg, #ffffff 0%, #bdd7ff 100%);
}

.form-header {
	padding: 1rem;
	margin-top: -3.13rem;
	margin-bottom: 1.5rem;
	color: #000;
	font-size: 25px;
	text-align: center;
	border-radius: 0.125rem;
	-webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
		0 4px 15px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.rounded {
	border-radius: 0.25rem !important;
}

.hover\:text-blue-600:hover {
	color: #fff600 !important;
}

.text-blue-500 {
	color: #000000 !important;
}

.signin_button {
	background-image: linear-gradient(120deg, #ffffff 0%, #bdd7ff 100%);
	width: 100%;
	height: 40px;
	border-radius: 0.25rem;
	color: #000;
	filter: drop-shadow(1px 1px 1px black);
}

.signin_button:hover {
	background-image: linear-gradient(120deg, #f8d800 0%, #ffeb00 100%);
}

input#userEmail {
	height: 33px;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	width: 302px !important;
	color: black;
	background: white;
}

input#resetEmail {
	height: 25px;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	width: 302px !important;
	color: black;
	background: white;
}

input#userPassword {
	height: 33px;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	width: 302px !important;
	color: black;
	background: white;
}

input#displayName {
	height: 33px;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	width: 302px !important;
	background: white;
	color: black;
}

label {
	background-color: #e66000 !important;
	bottom: -12px !important;
	position: relative !important;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.img_login:hover {
	filter: drop-shadow(2px 4px 6px black);
}

.box_errore {
	background: darkred;
	border-radius: 0.25rem;
	padding: 10px;
	filter: drop-shadow(2px 4px 6px black);
	top: 9px;
	position: relative;
}

label.w-full.block {
	bottom: -8px !important;
}
