.testo_user {
	color: black;
	text-align: center;
	font-size: 19px;
}

.navbar-toggler span {
	display: block;
	height: 25px !important;
	background-color: #fff0 !important;
	margin-top: 4px;
	margin-bottom: 4px;
	transform: rotate(0deg);
	left: 0;
	opacity: 1;
}

.navbar-toggler span:hover {
	filter: drop-shadow(2px 4px 6px black);
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: relative !important;
	left: 0px !important;
	top: 0px !important;
	transform: rotate(0deg) !important;
	opacity: 0.9 !important;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
	background-color: #ff6a00 !important;
	font-weight: 400;
	border-radius: 0px !important;
	filter: drop-shadow(0px 0.5px 1px grey) !important;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
	background-color: rgba(194, 78, 0, 0.274);
	border-radius: 0.25rem;
}

.navbar .dropdown-menu a:hover {
	font-size: 0.9375rem;
	font-weight: 300;
	padding: 10px;
	background: #ff6a00 !important;
	border-radius: 0px !important;
	color: black !important;
}

.navbar-dark .navbar-brand {
	color: #000 !important;
	align-self: center;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.44);
}

a#collasible-nav-dropdown {
	color: black;
}

.dropdown-item.active {
	background-color: #ff6a00 !important;
}

.navbar-nav {
	align-items: center;
}
